import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({

    textBottom: {
        marginTop: "1vw",
        marginBottom: "0px",
        padding: "0.2vw",
        textAlign: "center",
        color: "#FF9300",
        fontSize: "1.7vw",
    },
    vertLine: {
        borderLeft: "0.4vw solid #1A5D71",
        borderRight: "0.4vw solid #1A5D71",
    },
    
}));

const Footer = () => {

    const classes = useStyles();

    return (

        <div className="footer">
            <hr className="ligne"/>
            <Grid container >
                <Grid item  xs={3} >
                    <Typography variant="h6" className={classes.textBottom}><b>0478 96 42 71</b></Typography>
                    
                </Grid>
                <Grid item xs={6} className={classes.vertLine}>
                    <Typography variant="h6" className={classes.textBottom}><b>2, Rue Mareyde 1150 Bruxelles</b></Typography>
                    
                </Grid>
                <Grid item  xs={3}>
                    <Typography 
                    variant="h6" 
                    className={classes.textBottom}>
                        <a className="mailto" href="mailto:dentiste.taita@gmail.com">
                            <b>dentiste.taita@gmail.com</b>
                        </a>
                    </Typography>
                    
                </Grid>

            </Grid>
            <div className="divBlanc"></div>
            </div>
    )
}

export default Footer;