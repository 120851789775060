import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({

    texteCovid: {
        marginTop: "4vh",
        marginBottom: "3vh",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "5vw"
    },
    texteH6: {
        marginTop: "0vw",
        marginBottom: "0.5vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "2.2vw",
    },
    texteBody2: {
        marginTop: "0vw",
        marginBottom: "3vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.4vw",
    },
    root: {
        '& > *': {
        margin: theme.spacing(4),
        },
        width:"100%",
        textAlign: "center",
    },

    buttonCovid: {
        backgroundColor: "#FF9300",
        color: "#FFFFFF",
        textTransform: "none",
    }
    



}));

const CovidAvant = () => {

    const classes = useStyles();

    return (
        <div className="divAccueil">

            <Typography className={classes.texteCovid}>
                <b>Avant votre RDV</b>
            </Typography>

            <Typography className={classes.texteH6}>
            <b>Prévenez-moi le plus rapidement possible si</b>
            </Typography>

            <Typography className={classes.texteBody2}>
            Vous avez de la température<br/>
Vous présentez un ou plusieurs symptômes tels que toux, perte de goût ou d’odorat, état fébrile ou difficulté respiratoire<br/>
Vous avez été en contact avec une personne diagnostiquée Covid ces 14 derniers jours
            </Typography>
            <Typography className={classes.texteH6}>
            <b>Nous devrons alors reporter votre RDV, il en va de la santé de tous!</b>
            </Typography>

        </div>
    )
}

export default CovidAvant;