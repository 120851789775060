import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import Cabinet from "../Images/CabinetJpeg.jpg";
import Salledattente from "../Images/SalledattenteJpeg.jpg";
import Celine from "../Images/CelineJpeg.jpg";


const useStyles = makeStyles(() => ({

    page: {
        display: "flex",
        marginTop: "2vw",
        marginBottom: "5vw",
        
    },
    image1: {
        height: "40vw",
        width: "30vw",
        marginBottom: "5vw",
        marginLeft: "5vw",

    },
    image2: {
        width: "30vw",
        height: "40vw",
        marginBottom: "5vw",

        marginRight: "5vw",
    },

    
    media: {
        height: "13.5vw",

    },
    
    card: {
        display: "inline-flex",
        justifyContent: 'center',
        marginLeft: "7vw",
        marginRight: "7vw",
        marginTop: "5vw",

    },
    groupecard: {
        
        width: "30vw"
    },
    portrait: {
        height: "21.24vw",
        width: "16vw",
    }

}));

const Photos = () => {

    const classes = useStyles();

    return (
        <div className={classes.page}>
            <img className={classes.image1} src={Cabinet} alt="" />
            <div className={classes.groupecard}>

                <div className={classes.card}>
                    <img className={classes.portrait} src={Celine} alt="" />
                    
                    
                </div>

                
            </div>
            <img className={classes.image2} src={Salledattente} alt="" />
        </div>
    )
}

export default Photos;