import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({

    texteCovid: {
        marginTop: "4vh",
        marginBottom: "5vh",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "5vw"
    },
    texteH6: {
        marginTop: "0vw",
        marginBottom: "0.5vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "2.2vw",
    },
    texteBody2: {
        marginTop: "0vw",
        marginBottom: "3vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.4vw",
    },
    root: {
        '& > *': {
            margin: theme.spacing(4),
        },
        width: "100%",
        textAlign: "center",
    },

}));

const CovidCabinet = () => {

    const classes = useStyles();

    return (
        <div className="divAccueil">

            <Typography className={classes.texteCovid}>
                <b>Lors de votre venue au cabinet</b>
            </Typography>

            <Typography className={classes.texteH6}>
                <b>Dans la salle d’attente</b>
            </Typography>

            <Typography className={classes.texteBody2}>
                Venez seul sauf si vous avez besoin d’aide (1 accompagnant maximum)<br />
                Portez un masque<br />
                Soyez ponctuel<br />
                Dès votre entrée, désinfectez vous les mains avec le gel hydro-alcoolique prévu à cet effet dans la salle d’attente
            </Typography>

            <Typography className={classes.texteH6}>
            <b>Dans la salle de soins, vous devrez</b>
            </Typography>

            <Typography className={classes.texteBody2}>
            Retirer votre masque uniquement à ce moment, votre accompagnant sera prié de garder le sien<br/>
            Faire un bain de bouche d’une minute juste avant le soin<br/>
            Remettre votre masque dès que votre soin est terminé

            </Typography>

        </div>
    )
}

export default CovidCabinet;