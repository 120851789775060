import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({

    texteCovid: {
        marginTop: "4vw",
        marginBottom: "1vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "5vw"
    },
    texteH6: {
        marginTop: "0vw",
        marginBottom: "1vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.8vw",
    },
    texteBody2: {
        marginTop: "0vw",
        marginBottom: "0vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.4vw",
    },
    root: {
        margin: "5vw",
        width: "90vw",
        textAlign: "center",
    },

    buttonCovid: {
        backgroundColor: "#FF9300",
        color: "#FFFFFF",
        textTransform: "none",
        width: "27vw",
        fontSize: "1.4vw",
        margin: "0 5vw 5vw 5vw"
    }




}));

const TexteCovid = ({ setAvantRdv, setPdtRdv, setCovidIsClicked }) => {

    const classes = useStyles();

    return (
        <div className="divAccueil">

            <Typography className={classes.texteCovid}>
                <b>Recommandations Covid-19</b>
            </Typography>

            <Typography className={classes.texteH6}>
                Veuillez, s’il vous plaît respecter les consignes suivantes lors de votre venue au cabinet
            </Typography>

            <Typography className={classes.texteBody2}>
                <b>Les soins vous seront prodigués avec un maximum de précautions :</b><br />
                    Equipements de protection individuels<br />
                    Ventilation de la salle de soins<br />
                <b>Désinfection minutieuse et aération naturelle entre chaque patient</b><br />
                    Ce protocole peut, dans certains cas, entraîner un peu de retard, soyez indulgents
            </Typography>
            <div className={classes.root}>
                <Button onClick={() => {
                    setAvantRdv(true)
                    setCovidIsClicked(false)
                }}
                    className={classes.buttonCovid}
                    variant="contained">
                    Avant votre RDV prévu</Button>
                <Button
                    onClick={() => {
                        setCovidIsClicked(false)
                        setPdtRdv(true)
                    }}
                    className={classes.buttonCovid}
                    variant="contained"
                >
                    Lors de votre venue au cabinet</Button>
            </div>
        </div>
    )
}

export default TexteCovid;