import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';




const useStyles = makeStyles((theme) => ({

    boutonMenu: {
        textTransform: "capitalize",
        flexGrow: 1,
        color: "#FFFFFF",
        fontSize: "2vw",
    },
    barMenu: {
        backgroundColor: "#1A5D71",
        
    },
    
    boutonBottom: {
        flexGrow: 1
    }

}));

const Header = ({setCovidIsClicked, setHomeIsClicked, setAvantRdv, setPdtRdv, setAccesIsClicked, setDetailIsClicked, setPhotosIsClicked}) => {

    const classes = useStyles();

    return (
        <div className="appBar">
            <AppBar className={classes.barMenu}>
                <Toolbar >
                    <Button 
                    onClick= {()=> {
                        setCovidIsClicked(false) 
                        setHomeIsClicked(true)
                        setAvantRdv(false)
                        setPdtRdv(false)
                        setAccesIsClicked(false)
                        setDetailIsClicked(false)
                        setPhotosIsClicked(false)
                        }} 
                    className={classes.boutonMenu}>
                        Accueil
                </Button>
                    <Button 
                    onClick= {()=> {
                        setCovidIsClicked(true) 
                        setHomeIsClicked(false)
                        setAvantRdv(false)
                        setPdtRdv(false)
                        setAccesIsClicked(false)
                        setDetailIsClicked(false)
                        setPhotosIsClicked(false)
                        }} 
                    className={classes.boutonMenu}>
                        Covid-19
                </Button>
                    <Button onClick={()=>{
                        setAccesIsClicked(true)
                        setHomeIsClicked(false)
                        setCovidIsClicked(false)
                        setAvantRdv(false)
                        setPdtRdv(false)
                        setDetailIsClicked(false)
                        setPhotosIsClicked(false)

                        } }
                    className={classes.boutonMenu}>
                        Accès
                </Button>
                    <Button onClick={()=>{
                        setAccesIsClicked(false)
                        setHomeIsClicked(false)
                        setCovidIsClicked(false)
                        setAvantRdv(false)
                        setPdtRdv(false)
                        setDetailIsClicked(true)
                        setPhotosIsClicked(false)
                        }}
                    className={classes.boutonMenu}>
                        Détails
                </Button>
                    <Button 
                    onClick={()=>{
                        setAccesIsClicked(false)
                        setHomeIsClicked(false)
                        setCovidIsClicked(false)
                        setAvantRdv(false)
                        setPdtRdv(false)
                        setDetailIsClicked(false)
                        setPhotosIsClicked(true)
                        }}
                    className={classes.boutonMenu}>
                        Photos
                </Button>
                </Toolbar>
            </AppBar>

            
        </div>
    )
}

export default Header;