import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import planBus from "../Images/PlanBusJpeg.jpg";
import planVoiture from "../Images/PlanVoitureJpeg.jpg";



const useStyles = makeStyles((theme) => ({

    texteCovid: {
        marginTop: "4vw",
        marginBottom: "4vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize:"5vw",
    },
    texteH6 :{
        marginTop: "0vw",
        marginBottom: "0.5vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "2vw",
    },
    texteBody2 :{
        marginTop: "0vw",
        marginBottom: "0vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.4vw",
    },
    root: {
        display: "inline",
        width: "40vw",
        textAlign: "center",
        marginLeft: "5vw",
        marginRight:"5vw",

    },
card:{
    display: "flex",
    justifyContent: 'center',
},

    buttonCovid: {
        backgroundColor: "#FF9300",
        color: "#FFFFFF",
        textTransform: "none",
        width: "40.24vw",
        pointerEvents: "none"
    },
    media:{
        height: "30vw",
        width: "40.24vw",
        marginBottom: "1vw"
    },



}));



const Acces = () => {

    const classes = useStyles();

    return (
        <div className="divAccueil">

            <Typography variant="h2" className={classes.texteCovid}>
                <b>Accès au cabinet</b>
            </Typography>
            <div className={classes.card}>
                <Card 
                elevation={0}
                className={classes.root}>
                    <div >

                        <Button
                            disableElevation
                            className={classes.buttonCovid}
                            variant="contained">
                            En transport en commun
                    </Button>
                        <CardContent>
                            <Typography className={classes.texteH6}><b>Bus 36</b></Typography>
                            <Typography className={classes.texteBody2}>Arrêt Mareyde à 130m<br/>
                             Arrêt Mertens 140m
                            </Typography>
                        </CardContent>
                        <CardMedia 
                        className={classes.media}
                        image={planBus}
                        />
                            
                        
                    </div>
                </Card>

                <Card 
                elevation={0}
                className={classes.root}>
                    <div >


                        <Button
                            disableElevation
                            className={classes.buttonCovid}
                            variant="contained">
                            En voiture
                    </Button>
                        <CardContent>
                            <Typography className={classes.texteH6}><b>Parking aisé</b></Typography>
                            <Typography className={classes.texteBody2}>Gratuit, pas de disque nécessaire<br/>
                            Prévoir 5’ de plus le mercredi PM (Hockey)
                            </Typography>
                            </CardContent>
                            <CardMedia 
                        className={classes.media}
                        image={planVoiture}
                        />
                    </div>
                </Card>
            </div>
           

        </div>
    )
}

export default Acces;