import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({

    texteTitre: {
        marginTop: "4vw",
        marginBottom: "1vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "5vw"
    },
    texteH6: {
        marginTop: "0vw",
        marginBottom: "0.5vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.8vw",
    },
    texteBody2: {
        marginTop: "0vw",
        marginBottom: "0vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.4vw",
    },


}));

const TexteDetail = () => {

    const classes = useStyles();

    return (
        <div className="divAccueil">
            <Typography className={classes.texteTitre}>
                <b>Détails pratiques</b>
            </Typography>
            <Typography className={classes.texteH6}>
                <b>Payment possible par Bancontact<br />
Indemnités demandées en cas RDV manqué (en fonction de la durée initialement prévue)</b>

       </Typography>
            <Typography className={classes.texteBody2}>
                Pour éviter ce désagrément, ayez l’amabilité de prévenir minimum 24h à l’avance afin que<br />
       votre RDV puisse profiter à un autre patient en attente
       </Typography>
            
       

        </div>
    )
}

export default TexteDetail;