import React, { useState } from "react";
import './App.css';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import TexteAccueil from "./Components/TexteAccueil";
import TexteCovid from "./Components/TexteCovid";
import CovidAvant from "./Components/CovidAvant";
import CovidCabinet from "./Components/CovidCabinet";
import Acces from "./Components/TexteAcces";
import Detail from "./Components/TexteDetail";
import Photos from "./Components/Photos";

function App() {

  const [homeIsClicked, setHomeIsClicked] = useState(true);
  const [covidIsClicked, setCovidIsClicked] = useState(false);
  const [avantRdv, setAvantRdv] = useState(false);
  const [pdtRdv, setPdtRdv] = useState(false);
  const [accesIsClicked, setAccesIsClicked] = useState(false);
  const [detailIsClicked, setDetailIsClicked]= useState(false);
  const [photosIsClicked, setPhotosIsClicked]= useState(false);

  return (
    <div>
      <Header
        setCovidIsClicked={setCovidIsClicked}
        setHomeIsClicked={setHomeIsClicked}
        setAvantRdv={setAvantRdv}
        setPdtRdv={setPdtRdv}
        setAccesIsClicked={setAccesIsClicked}
        setDetailIsClicked={setDetailIsClicked}
        setPhotosIsClicked={setPhotosIsClicked}
      />

      { homeIsClicked &&
        <TexteAccueil />}

      { covidIsClicked &&
        <TexteCovid
          setAvantRdv={setAvantRdv}
          setPdtRdv={setPdtRdv}
          setCovidIsClicked={setCovidIsClicked}
        />}

      { avantRdv &&
        <CovidAvant />}

      {pdtRdv &&
        <CovidCabinet />
      }

      {accesIsClicked &&
        <Acces />
      }

      {detailIsClicked &&
      <Detail />
      }

      {photosIsClicked &&
      <Photos/>}

      <Footer />

    </div>
  );
}

export default App;
