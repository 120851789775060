import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({

    texteAccueil: {
        marginTop: "5vw",
        marginBottom: "0vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "6vw",
    },
    texteH6 :{
        marginTop: "0vw",
        marginBottom: "3vW",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "2.5vw",
    },
    texteBody2 :{
        marginTop: "0vw",
        marginBottom: "5vw",
        textAlign: "center",
        color: "#1A5D71",
        fontSize: "1.5vw",
    },
    
}));

const TexteAccueil = () => {

    const classes = useStyles();

    return (
        <div className="divAccueil">
       <Typography className={classes.texteAccueil}>
           <b>Céline Taïta</b>
       </Typography> 
       <Typography className={classes.texteH6}>
           <b>Dentiste spécialisée en prothèse</b>
       </Typography>
       <Typography className={classes.texteBody2}>
           Licenciée en science dentaire UCL 1997<br/>
Diplômée d’études spécialisées en réhabilitation prothétique UCL 2000<br/>
Consultante externe en réhabilitation prothétique UCL 2000 - 2011<br/>
Pratique privée depuis 2000
       </Typography>
       </div>
    )
}

export default TexteAccueil;